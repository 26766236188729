import { HTTP, Pagination } from '@/core';
import {
  OrderItem,
  StoreOrderRequest,
  FindAllOptions,
  UpdateOrderStageRequest,
  OrderLocationItem,
  OrderStageItem,
  OrderArchiveItemShort,
  OrdersArchiveFindAllOptions,
  OrderArchiveItemFull, UpdateOrderRequest, OrderItemShort, OrderTimingItem,
} from './interface';

export class OrderService {
  static async store(order: StoreOrderRequest): Promise<OrderItem> {
    const { data } = await HTTP.post('orders', order);

    return data;
  }

  static async duplicate(orderId: string): Promise<OrderItem> {
    const { data } = await HTTP.post(`orders/${orderId}`);

    return data;
  }

  static async findAllNew(params: FindAllOptions): Promise<Pagination<OrderItemShort>> {
    const { data } = await HTTP.get('orders/v2', { params });

    return data;
  }
  static async findAllNewVTwo(params: FindAllOptions): Promise<Pagination<OrderItemShort>> {
    const { data } = await HTTP.get('orders/v2/delayed', { params });

    return data;
  }
  static async dummarageAdding(): Promise<any> {
    const { data } = await HTTP.get('orders/v2/demurrage');

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<OrderItem>> {
    const { data } = await HTTP.get('orders', { params });

    return data;
  }

  static async findOne(id: string): Promise<OrderItem> {
    const { data } = await HTTP.get(`orders/${id}`);

    return data;
  }

  static async findTiming(id: string): Promise<OrderTimingItem> {
    const { data } = await HTTP.get(`v1/orders/${id}/timing`);

    return data;
  }

  static async update(orderId: string, order: UpdateOrderRequest): Promise<OrderItem> {
    const { data } = await HTTP.patch(`orders/${orderId}`, order);
    return data;
  }

  static async approve(orderId: string, rejectReason: string | null): Promise<void> {
    await HTTP.patch(`orders/${orderId}/approve`, { rejectReason });
  }

  static async reopen(orderId: string): Promise<void> {
    await HTTP.patch(`orders/${orderId}/reopen`);
  }

  static async updateStage(orderId: string, stage: UpdateOrderStageRequest): Promise<void> {
    await HTTP.patch(`orders/${orderId}/stage`, stage);
  }

  static async getStagesHistory(orderId: string): Promise<OrderStageItem[]> {
    const { data } = await HTTP.get(`orders/${orderId}/stage`);

    return data;
  }

  static async delete(orderId: string): Promise<void> {
    await HTTP.delete(`orders/${orderId}`);
  }

  static async getLocations(): Promise<OrderLocationItem[]> {
    const { data } = await HTTP.get('orders/locations');

    return data;
  }

  static async cargoDelivered(orderId: string, cargoId: string): Promise<void> {
    await HTTP.patch(`orders/${orderId}/cargos/${cargoId}/delivered`);
  }

  static async moveToArchive(id: string): Promise<void> {
    await HTTP.post(`orders/archive/${id}`);
  }

  static async findAllArchive(options: OrdersArchiveFindAllOptions): Promise<Pagination<OrderArchiveItemShort>> {
    const { data } = await HTTP.get<Pagination<OrderArchiveItemShort>>('orders/archive', { params: options });

    return data;
  }

  static async findOneArchive(id: string): Promise<OrderArchiveItemFull> {
    const { data } = await HTTP.get<OrderArchiveItemFull>(`orders/archive/${id}`);

    return data;
  }
}
